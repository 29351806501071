import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import SectionContainer from '../SectionContainer';

import css from './SectionFeaturesProductsSection.module.css';
import { setActiveListing } from '../../../SearchPage/SearchPage.duck';
import { ListingCard } from '../../../../components';

const NextArrow = ({ onClick, isVisible }) =>
  isVisible && (
    <div
      className={css.customArrow}
      onClick={onClick}
      style={{
        right: 'calc(-18px + 32px)',
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50px" data-rtl-flip="true">
        <path d="M8.913 19.236a.9.9 0 0 0 .642-.266l6.057-6.057a1.3 1.3 0 0 0 .388-.945c.008-.35-.123-.69-.364-.945L9.58 4.966a.91.91 0 0 0-1.284 0 .896.896 0 0 0 0 1.284l5.694 5.718-5.718 5.718a.896.896 0 0 0 0 1.284.88.88 0 0 0 .642.266"></path>
      </svg>
    </div>
  );

const PrevArrow = ({ onClick, isVisible }) =>
  isVisible && (
    <div
      className={css.customArrow}
      onClick={onClick}
      style={{
        left: 'calc(-18px + 32px)',
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50px" data-rtl-flip="true">
        <path d="M15.087 19.236a.9.9 0 0 1-.642-.266l-6.057-6.057A1.3 1.3 0 0 1 8 11.968c-.008-.35.123-.69.364-.945l6.057-6.057a.91.91 0 0 1 1.284 0 .895.895 0 0 1 0 1.284l-5.694 5.718 5.718 5.718a.896.896 0 0 1 0 1.284.88.88 0 0 1-.642.266"></path>
      </svg>
    </div>
  );

const cardRenderSizes = isMapVariant => {
  if (isMapVariant) {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 62.5;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  } else {
    // Panel width relative to the viewport
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  }
};

// Section component that shows features.
// Block content are shown in a row-like way:
// [image] text
// text [image]
// [image] text
const SectionFeaturesProductsSection = props => {
  const {
    sectionId,
    className,
    rootClassName,
    title,
    description,
    appearance,
    options,
    listings,
    onSetRandomListings,
    random12Listings,
  } = props;
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  useEffect(() => {
    // Dynamically import the slick carousel styles
    import('slick-carousel/slick/slick.css');
    import('slick-carousel/slick/slick-theme.css');
    // Reinitialize carousel
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(1);
    }
  }, []);

  useEffect(() => {
    if (listings?.length && !random12Listings?.length) {
      onSetRandomListings(listings);
    }
  }, [listings]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    nextArrow: (
      <NextArrow
        isVisible={currentSlide < random12Listings.length - (typeof window !== 'undefined' && window.innerWidth < 1024 ? 2 : 4)}
      />
    ),
    prevArrow: <PrevArrow isVisible={currentSlide > 0} />,
    centerPadding: 20,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <h2 className={css.heading}>{title?.content}</h2>
      <p className={css.subheading}>{description?.content}</p>
      {random12Listings.length && (
        <Slider {...settings} className={css.productsSlider}>
          {random12Listings.map(listing => (
            <ListingCard
              className={css.listingCard}
              key={listing.id.uuid}
              listing={listing}
              renderSizes={cardRenderSizes(false)}
              setActiveListing={setActiveListing}
            />
          ))}
        </Slider>
      )}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFeaturesProductsSection.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  blocks: [],
  isInsideContainer: false,
  options: null,
};

SectionFeaturesProductsSection.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  blocks: arrayOf(object),
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionFeaturesProductsSection;
